import AnchorLink from "react-anchor-link-smooth-scroll"
import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"
import TreatmentsLinksWrapper from "./treatmentsLinks.style"

const TreatmentsLinks = ({ sectionOptions }) => {
  return (
    <TreatmentsLinksWrapper>
      <Container width="898px" noGutter>
        <Fade up delay={100}>
          <Box {...sectionOptions}>
            <p>Jump to:</p>
            <AnchorLink
              className="smooth_scroll"
              href="#Aesthetics"
              offset="100"
            >
              Aesthetics
            </AnchorLink>
            <AnchorLink className="smooth_scroll" href="#Beauty" offset="100">
              Beauty
            </AnchorLink>
            <AnchorLink className="smooth_scroll" href="#Health" offset="100">
              Health
            </AnchorLink>
            {/* <AnchorLink className="smooth_scroll" href="#Slim" offset="100">
              Slim
            </AnchorLink> */}
            <AnchorLink className="smooth_scroll" href="#Travel" offset="100">
              Travel
            </AnchorLink>
            {/* <AnchorLink className="smooth_scroll" href="#Clinic" offset="100">
              Clinic
            </AnchorLink> */}
          </Box>
        </Fade>
      </Container>
    </TreatmentsLinksWrapper>
  )
}

// PricesAndOffers style props
TreatmentsLinks.propTypes = {
  sectionOptions: PropTypes.object,
}

// PricesAndOffers default style
TreatmentsLinks.defaultProps = {
  // section options default style
  sectionOptions: {
    mx: "auto",
    flexBox: true,
    justifyContent: "space-evenly",
    alignItems: "center",
    bg: "#fff",
    maxWidth: "495px",
    minHeight: "70px",
    borderRadius: "16px",
    boxShadow: "0 0 20px rgba(175, 182, 194, 0.3)",
  },
}

export default TreatmentsLinks
