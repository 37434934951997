import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const PricesWrapper = styled.section`
  padding: 80px 0 0 0;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 62px 0 0 0;
  }
  @media (max-width: 767px) {
    padding: 62px 0 0 0;
  }

  a {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
  }
`

export const ContentWrapper = styled.div`
  /* column-count: 2;
  column-gap: 80px; */
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    margin-top: -30px;
  }

  .react-reveal {
    flex: 0 0 calc(50% - 40px);
    margin-bottom: 40px;
    &:nth-child(odd) {
      margin-right: 80px;
    }
    @media only screen and (max-width: 768px) {
      flex: 0 0 calc(50% - 25px);
      &:nth-child(odd) {
        margin-right: 50px;
      }
    }
    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
    }
  }
`

export const PriceItem = styled.div`
  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-transform: capitalize;
    letter-spacing: 0.96px;
    text-transform: lowercase;
    color: ${themeGet("colors.textColor")};
    margin-bottom: 16px;
    font-weight: 600;
  }
  h4:first-letter {
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    color: ${themeGet("colors.textColor")};
    margin-bottom: 0px;
  }

  .price {
    color: ${themeGet("colors.primaryBotton", "#297E7F")};
    font-weight: 600;
  }

  .comments {
    font-size: 14px;
    font-weight: 600;
  }
`

export const ProductDetails = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ProductWrapper = styled.div`
  margin-bottom: 8px;
`
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 16px;

  .priceListButton {
    text-transform: inherit;
    margin-right: 36px;
    color: ${themeGet("colors.secondaryTextColor", "#fff")};
    background-color: ${themeGet("colors.white", "#fff")};
    border: 1px solid ${themeGet("colors.primaryBotton", "#fff")};
    @media only screen and (max-width: 1440px) {
      margin-right: 36px;
    }
    @media only screen and (max-width: 1360px) {
      margin-right: 24px;
    }
    @media only screen and (max-width: 991px) {
      margin-right: 16px;
    }
    @media only screen and (max-width: 767px) {
      margin-right: 0px;
    }
    @media only screen and (max-width: 440px) {
      margin-right: 0px;
      margin-bottom: 8px;
    }
    &:hover {
      background-color: ${themeGet("colors.primaryBotton", "#297E7F")};
      border: 1px solid ${themeGet("colors.primaryBotton", "#297E7F")};
      color: ${themeGet("colors.white", "#fff")};
    }
  }
  .bookButton {
    text-transform: inherit;
    margin-right: 0px;
    color: ${themeGet("colors.white", "#fff")};
    background-color: ${themeGet("colors.secondaryBotton", "#886638")};
    border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
    &:hover {
      background-color: #fbfbf9;
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      color: ${themeGet("colors.secondaryBotton", "#886638")};
    }
  }
`
export default PricesWrapper
