import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const TreatmentsLinksWrapper = styled.section`
  padding: 40px 0 0 0;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 32px 0 0 0;
  }
  @media (max-width: 767px) {
    padding: 24px 0 0 0;
  }

  a {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    @media (max-width: 767px) {
      font-size: 13px;
    }
  }

  p {
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
`

export default TreatmentsLinksWrapper
