import PricesWrapper, {
  ButtonGroup,
  ContentWrapper,
  PriceItem,
  ProductDetails,
  ProductWrapper,
} from "./prices.style"
import { graphql, useStaticQuery } from "gatsby"

import Box from "../../components/Box"
import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import PropTypes from "prop-types"
import React from "react"
import Text from "../../components/Text"
import aesthetic from "../../common/assets/image/elegant/changeYourLife/aesthetics.png"
import health from "../../common/assets/image/elegant/changeYourLife/heart.png"
import slim from "../../common/assets/image/elegant/changeYourLife/slim.png"

const Prices = ({ priceTitle, sectionPrice, priceImage }) => {
  const data = useStaticQuery(graphql`
    query PricesDetails {
      allContentfulPricesMainCategories(
        sort: { fields: categoryName, order: ASC }
      ) {
        edges {
          node {
            categoryName
            id
            productCategory {
              productCategory
              products {
                name
                comments
                price
              }
            }
          }
        }
      }
    }
  `)

  const pricesContent = data.allContentfulPricesMainCategories.edges

  return (
    <PricesWrapper>
      <Container width="958px">
        {pricesContent.map(({ node }, index) => {
          if (node.id === "d5defe0e-40a3-5c24-b147-a34f418eb09a") {
            return null
          }
          return (
            <Box {...sectionPrice} key={index} id={node.categoryName}>
              <Fade up delay={100}>
                <Image
                  src={
                    node.categoryName === "Aesthetic"
                      ? aesthetic
                      : node.categoryName === "Slim"
                      ? slim
                      : node.categoryName === "Health"
                      ? health
                      : aesthetic
                  }
                  alt="aesthetics"
                  {...priceImage}
                />
                <Heading
                  content={`${node.categoryName} price list`}
                  {...priceTitle}
                />
              </Fade>
              <ContentWrapper>
                {node.productCategory.map(
                  ({ productCategory, products }, index) => {
                    return (
                      <>
                        {products && (
                          <Fade up delay={100} key={index}>
                            <PriceItem>
                              <Heading as="h4" content={productCategory} />
                              {products.map(
                                ({ name, price, comments }, index) => {
                                  return (
                                    <ProductWrapper key={index}>
                                      <ProductDetails>
                                        <Text content={name} />
                                        <Text
                                          content={
                                            price !== null ? `£${price}` : null
                                          }
                                          className="price"
                                        />
                                      </ProductDetails>
                                      {comments && (
                                        <Text
                                          content={comments}
                                          className="comments"
                                        />
                                      )}
                                    </ProductWrapper>
                                  )
                                }
                              )}
                            </PriceItem>
                          </Fade>
                        )}
                      </>
                    )
                  }
                )}
              </ContentWrapper>
              <Fade up delay={300}>
                <ButtonGroup>
                  {/* <a href="https://google.com">
                    <Button
                      title="Download our pricelist"
                      className="priceListButton"
                    />
                  </a> */}
                  <a
                    href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button title="Book a treatment" className="bookButton" />
                  </a>
                </ButtonGroup>
              </Fade>
            </Box>
          )
        })}
      </Container>
    </PricesWrapper>
  )
}

// PricesAndOffers style props
Prices.propTypes = {
  priceTitle: PropTypes.object,
  sectionPrice: PropTypes.object,
  priceImage: PropTypes.object,
}

// PricesAndOffers default style
Prices.defaultProps = {
  // section price default style
  sectionPrice: {
    mb: ["64px", "120px"],
  },
  //price title default style
  priceTitle: {
    letterSpacing: "1.28px",
    color: "333333",
    textAlign: "center",
    fontWeight: "300",
    mb: ["64px", "48px"],
    fontSize: "32px",
  },
  //price image default style
  priceImage: {
    mx: "auto",
    height: "80px",
    mb: "8px",
  },
}

export default Prices
