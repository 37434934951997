import { ContentWrapper } from "../containers/elegant.style"
import Fade from "react-reveal/Fade"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import Prices from "../containers/Prices"
import React from "react"
import SEO from "../components/SEO"
import TreatmentsLinks from "../containers/TreatmentsLinks"

const PricesPage = ({ location }) => {
  const url = location.href ? location.href : ""
  return (
    <>
      <SEO
        url={url}
        title="Treatment Prices"
        shortDesc="Elegant Beauty Clinic"
        description="Find a complete price list of our services to help you choose your next aesthetic service, facial, blood test or travel vaccination. Book online today."
      />
      <Layout>
        <ContentWrapper>
          <PageTitle title="Our Elegant price list" subtitle="Elegant prices" />
          <TreatmentsLinks />
          <Fade up delay={100}>
            <div className="info-text">
              <p>
                Prices are correct as of <b>20th November 2023</b> but these are
                subject to change.
              </p>
              <p>Correct prices are always on our online booking system.</p>
              <p>
                We do our best to keep our prices as cost effective as possible.
              </p>
            </div>
          </Fade>
          <Prices />
          <div className="priceextra">
            <p>
              {" "}
              'Health' prices for medications also include a £10 prescription
              fee which is built into the price.
            </p>
            <p>
              Medichecks list is not exhaustive and you should contact us if you
              can't find the test you need.
            </p>
            <p>
              The health consultation is free, but if you require a prescription
              as a result, it is subject to a fee for processing.
            </p>
          </div>
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default PricesPage
